import axios from 'axios';
import { setProperties, setLoading, setTotalCount } from '../reducers/propertiesReducer';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : (process.env.REACT_APP_API_URL || 'https://flippinarq.com/api');

export const fetchProperties = (limit = 100, offset = 0, filters = {}) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.post(`${apiUrl}/get-properties-by-filters`, { filtros: filters, limit, offset });
    console.log('RESPONSE:', response);
    dispatch(setProperties(response.data.properties));
    dispatch(setTotalCount(response.data.totalCount)); // Asegúrate de establecer el total de propiedades
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchFilteredProperties = (limit, offset, filtros) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.post(`${apiUrl}/get-properties-by-filters`, {
      limit,
      offset,
      filtros,
    });
    dispatch(setProperties(response.data.properties));
    dispatch(setTotalCount(response.data.totalCount)); // Establecer el total de propiedades filtradas
  } catch (error) {
    console.error('Error al obtener propiedades filtradas:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

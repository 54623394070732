import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../redux/reducers/propertiesReducer';
import styles from './Login.module.css';
import logo from '../../assets/flippin-icon.jpeg';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((username === 'yamil' && password === 'adminflippin') || (username === 'lucas' && password === 'adminflippin') || (username === 'juan' && password === 'adminflippin')) {
      dispatch(loginSuccess({ username }));
      dispatch(setLoading(true)); // Set loading to true when login is successful
      navigate('/propiedades'); // Redirect to dashboard after login
    } else {
      alert('Invalid credentials');
    }
  };

  if (isAuthenticated) {
    navigate('/propiedades');
    return null;
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Flippin Logo" className={styles.logo} />
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>Login</button>
      </form>
    </div>
  );
};

export default Login;

import axios from 'axios';
import { setLoading as setPropertiesLoading, setProperties, setTotalCount } from '../reducers/propertiesReducer';

export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SAVE_FILTERS_SUCCESS = 'SAVE_FILTERS_SUCCESS';
export const SAVE_FILTERS_FAILURE = 'SAVE_FILTERS_FAILURE';
export const FETCH_SAVED_FILTERS_SUCCESS = 'FETCH_SAVED_FILTERS_SUCCESS';
export const FETCH_SAVED_FILTERS_FAILURE = 'FETCH_SAVED_FILTERS_FAILURE';
export const SET_LOADING = 'SET_LOADING';
export const FETCH_FILTER_STATS_SUCCESS = 'FETCH_FILTER_STATS_SUCCESS';
export const FETCH_FILTER_STATS_FAILURE = 'FETCH_FILTER_STATS_FAILURE';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : (process.env.REACT_APP_API_URL || 'https://flippinarq.com/api');

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

const saveFiltersSuccess = () => ({
  type: SAVE_FILTERS_SUCCESS,
});

const saveFiltersFailure = (error) => ({
  type: SAVE_FILTERS_FAILURE,
  payload: error,
});

const fetchSavedFiltersSuccess = (filters) => ({
  type: FETCH_SAVED_FILTERS_SUCCESS,
  payload: filters,
});

const fetchSavedFiltersFailure = (error) => ({
  type: FETCH_SAVED_FILTERS_FAILURE,
  payload: error,
});

const fetchFilterStatsSuccess = (stats) => ({
  type: FETCH_FILTER_STATS_SUCCESS,
  payload: stats,
});

const fetchFilterStatsFailure = (error) => ({
  type: FETCH_FILTER_STATS_FAILURE,
  payload: error,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const saveFilters = (nombre, encargado, filtros) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await axios.post(`${apiUrl}/save-filters`, {
      nombre,
      encargado,
      filtros: JSON.stringify(filtros),
    });
    dispatch(saveFiltersSuccess());
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(saveFiltersFailure(error));
    dispatch(setLoading(false));
  }
};

export const fetchSavedFilters = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(`${apiUrl}/get-saved-filters`);
    dispatch(fetchSavedFiltersSuccess(response.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(fetchSavedFiltersFailure(error));
    dispatch(setLoading(false));
  }
};

export const fetchFilterStats = (filtros) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.post(`${apiUrl}/get-filter-stats`, { filtros });
    dispatch(fetchFilterStatsSuccess(response.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(fetchFilterStatsFailure(error));
    dispatch(setLoading(false));
  }
};

export const handleLoadFilteredData = (filter) => async (dispatch) => {
  const filtros = JSON.parse(filter.Filtros);
  dispatch(setLoading(true));
  try {
    const response = await axios.post(`${apiUrl}/get-properties-by-filters`, { filtros });
    dispatch(setProperties(response.data.properties));
    dispatch(setTotalCount(response.data.totalCount)); // Establecer el total de propiedades filtradas
    dispatch(setLoading(false));
  } catch (error) {
    console.error('Error al cargar los datos filtrados:', error);
    dispatch(setLoading(false));
    throw error;
  }
};

export const fetchFilteredProperties = (limit, offset, filtros) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.post(`${apiUrl}/get-properties-by-filters`, {
      limit,
      offset,
      filtros,
    });
    dispatch(setProperties(response.data.properties));
    dispatch(setTotalCount(response.data.totalCount)); // Establecer el total de propiedades filtradas
    dispatch(setLoading(false));
  } catch (error) {
    console.error('Error al obtener propiedades filtradas:', error);
    dispatch(setLoading(false));
  }
};

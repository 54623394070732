import axios from 'axios';
import { markAsRevised } from '../reducers/propertiesReducer';
import { setLoading } from '../reducers/propertiesReducer';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : (process.env.REACT_APP_API_URL || 'https://flippinarq.com/api');

export const updatePropertyStatus = (id, estado) => async (dispatch, getState) => {
  const { user } = getState().auth; // Obtener el usuario del estado auth
  try {
    dispatch(setLoading(true));
    const response = await axios.put(`${apiUrl}/update-property`, { id, estado, encargado: user.username });
    if (response.status === 200) {
      dispatch(markAsRevised({ id, estado, encargado: user.username }));
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.error('Error updating property status:', error);
    dispatch(setLoading(false));
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalLoading from '../ModalLoading/ModalLoading';
import { fetchCRMData } from '../../redux/actions/crmActions';
import StatisticsModal from './StatisticsModal/StatisticsModal';
import styles from './CRMScreen.module.css';

const CRMScreen = () => {
  const dispatch = useDispatch();
  const crmData = useSelector((state) => state.crm.data);
  const loading = useSelector((state) => state.crm.loading);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCRMData());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredData = crmData.filter((row) => {
    return Object.values(row).some((value) =>
      value ? value.toString().toLowerCase().includes(searchQuery) : false
    );
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getChartData = (data, key) => {
    const counts = data.reduce((acc, row) => {
      const value = row[key] ? row[key].toString() : 'Nadie';
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});

    const total = Object.values(counts).reduce((acc, value) => acc + value, 0);

    const processedData = Object.keys(counts).reduce((acc, label) => {
      const percentage = (counts[label] / total) * 100;
      if (percentage <= 1) {
        acc['Otros'] = (acc['Otros'] || 0) + counts[label];
      } else {
        acc[label] = counts[label];
      }
      return acc;
    }, {});

    if (processedData['Nadie'] && processedData['Otros']) {
      processedData['Otros'] += processedData['Nadie'];
      delete processedData['Nadie'];
    }

    const labels = Object.keys(processedData);
    const chartData = Object.values(processedData);

    return { 
      labels, 
      data: chartData, 
      backgroundColor: labels.map((_, index) => `rgba(${index * 30 % 255}, ${index * 60 % 255}, ${index * 90 % 255}, 0.6)`) 
    };
  };

  const captadoresData = getChartData(crmData, 'Captador');
  const inmobiliariasData = getChartData(crmData, 'Inmobiliaria');
  const zonasData = getChartData(crmData, 'Zona_de_influencia');

  // Calculamos las métricas adicionales
  const inmobiliariaA = filteredData.filter(row => row.Inmobiliaria_A_B === 'Inmobiliaria A').length;
  const inmobiliariaB = filteredData.filter(row => row.Inmobiliaria_A_B === 'Inmobiliaria B').length;
  const uniqueAgentes = filteredData.filter(row => row.Agente_Broker === 'Agente').length;
  const uniqueBrokers = filteredData.filter(row => row.Agente_Broker === 'Broker').length;

  if (loading) {
    return <ModalLoading isLoading={loading} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Buscar..."
          value={searchQuery}
          onChange={handleSearch}
          className={styles.searchInput}
        />
        <button onClick={handleOpenModal} className={styles.statsButton}>Ver Estadísticas</button>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Agente Broker</th>
                <th>Inmobiliaria</th>
                <th>Celular</th>
                <th>Zona de Influencia</th>
                <th>Mail</th>
                <th>Puntuación</th>
                <th>Comentarios</th>
                <th>Captador</th>
                <th>Inmobiliaria A/B</th>
                <th>Fecha Ultimo Contacto</th>
                <th>Fecha Proximo Contacto</th>
                <th>Instagram Flippin</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={index}>
                  <td>{row.Nombre}</td>
                  <td>{row.Agente_Broker}</td>
                  <td>{row.Inmobiliaria}</td>
                  <td>{row.Celular}</td>
                  <td>{row.Zona_de_influencia}</td>
                  <td>{row.Mail}</td>
                  <td>{row.Puntuacion}</td>
                  <td>{row.Comentarios}</td>
                  <td>{row.Captador}</td>
                  <td>{row.Inmobiliaria_A_B}</td>
                  <td>{row.Fecha_de_contacto}</td>
                  <td>{row.Fecha_proximo_contacto}</td>
                  <td>{row.Instagram_Flippin}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.rowCountContainer}>
        <div className={styles.rowCount}>{`Agentes Inmobiliarios: ${filteredData.length}`}</div>
        <div className={styles.rowCount}>{`Inmobiliaria A: ${inmobiliariaA}`}</div>
        <div className={styles.rowCount}>{`Inmobiliaria B: ${inmobiliariaB}`}</div>
        <div className={styles.rowCount}>{`Cantidad Agentes: ${uniqueAgentes}`}</div>
        <div className={styles.rowCount}>{`Cantidad Brokers: ${uniqueBrokers}`}</div>
      </div>
      <StatisticsModal 
        isOpen={isModalOpen} 
        onRequestClose={handleCloseModal} 
        captadoresData={captadoresData} 
        inmobiliariasData={inmobiliariasData} 
        zonasData={zonasData} 
      />
    </div>
  );
};

export default CRMScreen;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalLoading.module.css';

const ModalLoading = ({ isLoading, text }) => {
  if (!isLoading) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.loadingSpinner}></div>
      {text && <div className={styles.loadingText}></div>}
    </div>
  );
};

ModalLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default ModalLoading;

import axios from 'axios';
import { setLoading, updateObservation } from '../reducers/propertiesReducer';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : (process.env.REACT_APP_API_URL || 'https://flippinarq.com/api');

export const updatePropertiesObservation = (id, observacion) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put(`${apiUrl}/update-property-observation`, { id, observacion });
    
    if (response.status === 200) {
      dispatch(updateObservation({ id, observacion }));
    }
    
    dispatch(setLoading(false));
  } catch (error) {
    console.error('Error updating property observation:', error);
    dispatch(setLoading(false));
  }
};

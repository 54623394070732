import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/reducers/authReducer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../../assets/flippin-icon.jpeg';

const Navbar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.properties.loading);

  // Cerramos el menú al presionar afuera o con escape
  const closeMenu = (e) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  // Agregamos el event listener
  useEffect(() => {
    window.addEventListener('keydown', closeMenu);
    return () => {
      window.removeEventListener('keydown', closeMenu);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const redirectToDashboard = () => {
    navigate('/propiedades');
  };

  const getTitle = () => {
    switch (location.pathname) {
      case '/propiedades':
        return 'Propiedades';
      case '/estadisticas':
        return 'Estadísticas';
      case '/filtros-guardados':
        return 'Filtros Guardados';
      case '/crm':
        return 'CRM';
      default:
        return '';
    }
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logoContainer} onClick={redirectToDashboard}>
        <img src={logo} alt="Flippin Logo" className={styles.logo} />
        <div className={styles.logoText}>Flippin</div>
      </div>
      <div className={styles.navTitle}>
        {getTitle()}
      </div>
      <div className={styles.hamburger} onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`${styles.menu} ${isOpen ? styles.open : ''}`}>
        <Link to="/crm" className={`${styles.menuItem} ${loading ? styles.disabledLink : ''}`} onClick={(e) => loading && e.preventDefault()}>CRM</Link>
        <Link to="/propiedades" className={`${styles.menuItem} ${loading ? styles.disabledLink : ''}`} onClick={(e) => loading && e.preventDefault()}>Propiedades</Link>
        <Link to="/filtros-guardados" className={`${styles.menuItem} ${loading ? styles.disabledLink : ''}`} onClick={(e) => loading && e.preventDefault()}>Filtros Guardados</Link>
        <Link to="/estadisticas" className={`${styles.menuItem} ${loading ? styles.disabledLink : ''}`} onClick={(e) => loading && e.preventDefault()}>Estadísticas</Link>
        
        <button onClick={handleLogout} className={`${styles.menuItem} ${styles.logoutButton}`}>Logout</button>
      </div>
    </nav>
  );
};

export default Navbar;

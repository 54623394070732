// reducers/filtersReducer.js
import { SET_FILTERS, CLEAR_FILTERS, SAVE_FILTERS_SUCCESS, SAVE_FILTERS_FAILURE, FETCH_SAVED_FILTERS_SUCCESS, FETCH_SAVED_FILTERS_FAILURE, SET_LOADING, FETCH_FILTER_STATS_SUCCESS, FETCH_FILTER_STATS_FAILURE } from '../actions/filtersActions';

const initialState = {
  filters: {},
  saveStatus: null,
  saveError: null,
  savedFilters: [],
  filterStats: {
    propertiesCount: 0,
    unreviewedPropertiesCount: 0,
  },
  loading: false,
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };
    case SAVE_FILTERS_SUCCESS:
      return {
        ...state,
        saveStatus: 'success',
        saveError: null,
      };
    case SAVE_FILTERS_FAILURE:
      return {
        ...state,
        saveStatus: 'failure',
        saveError: action.payload,
      };
    case FETCH_SAVED_FILTERS_SUCCESS:
      return {
        ...state,
        savedFilters: action.payload,
        loading: false,
      };
    case FETCH_SAVED_FILTERS_FAILURE:
      return {
        ...state,
        savedFilters: [],
        loading: false,
      };
    case FETCH_FILTER_STATS_SUCCESS:
      return {
        ...state,
        filterStats: action.payload,
        loading: false,
      };
    case FETCH_FILTER_STATS_FAILURE:
      return {
        ...state,
        filterStats: { propertiesCount: 0, unreviewedPropertiesCount: 0 },
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default filtersReducer;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './components/Auth/Login';
import PropertiesTable from './components/PropertiesTable/PropertiesTable';
import Statistics from './components/Statistics/Statistics';
import Navbar from './components/Navbar/Navbar';
import FiltrosGuardados from './components/FiltrosGuardados/FiltrosGuardados';
import CRMScreen from './components/CRM/CRMScreen';

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      {isAuthenticated && <Navbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/crm" element={isAuthenticated ? <CRMScreen /> : <Navigate to="/login" />} />
        <Route path="/propiedades" element={isAuthenticated ? <PropertiesTable /> : <Navigate to="/login" />} />
        <Route path="/filtros-guardados" element={isAuthenticated ? <FiltrosGuardados /> : <Navigate to="/login" />} />
        <Route path="/estadisticas" element={isAuthenticated ? <Statistics /> : <Navigate to="/login" />} />
        <Route path="*" element={<Navigate to={isAuthenticated ? "/propiedades" : "/login"} />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSavedFilters, fetchFilterStats, handleLoadFilteredData, setFilters } from '../../redux/actions/filtersActions';
import ModalLoading from '../ModalLoading/ModalLoading';
import FilterDetailsModal from './FilterDetailsModal/FilterDetailsModal';
import styles from './FiltrosGuardados.module.css';

const FiltrosGuardados = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedFilters = useSelector((state) => state.filters.savedFilters);
  const loading = useSelector((state) => state.filters.loading);
  const filterStats = useSelector((state) => state.filters.filterStats);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchSavedFilters());
  }, [dispatch]);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    dispatch(fetchFilterStats(JSON.parse(filter.Filtros)));
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFilter(null);
  };

  const handleLoadFilteredDataAndNavigate = async (filter) => {
    try {
      const filtros = JSON.parse(filter.Filtros);
      await dispatch(handleLoadFilteredData(filter));
      dispatch(setFilters(filtros)); // Asegurarse de establecer los filtros en Redux
      navigate('/propiedades');
    } catch (error) {
      console.error('Error al cargar los datos filtrados y redirigir:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Filtros Guardados</h2>
      <ModalLoading isLoading={loading} text="Cargando filtros guardados..." />
      <div className={styles.filtersList}>
        {savedFilters.map((filter) => (
          <div key={filter.id} className={styles.filterItem}>
            <div onClick={() => handleFilterClick(filter)}>
              <h3>{filter.Nombre}</h3>
              <p>Encargado: {filter.Encargado}</p>
              <p>Fecha: {new Date(filter.Fecha_creado).toLocaleDateString()}</p>
              <div className={styles.unreviewedCount}>
                {filter.unreviewedCount}
              </div>
            </div>
            <button className={styles.loadButton} onClick={() => handleLoadFilteredDataAndNavigate(filter)}>Cargar Datos</button>
          </div>
        ))}
      </div>
      <FilterDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        filter={selectedFilter}
        filterStats={filterStats}
      />
      {loading && <ModalLoading isLoading={loading} text="Cargando datos filtrados..." />}
    </div>
  );
};

export default FiltrosGuardados;

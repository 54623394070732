import { createSlice } from '@reduxjs/toolkit';

const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    properties: [],
    loading: false,
    totalCount: 0,
  },
  reducers: {
    setProperties: (state, action) => {
      console.log('GET DATA:', action.payload);
      state.properties = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    markAsRevised: (state, action) => {
      const { id, estado, encargado } = action.payload;
      const propertyIndex = state.properties.findIndex(property => property.Propiedad_id === id);
      if (propertyIndex !== -1) {
        state.properties[propertyIndex].Estado = estado;
        state.properties[propertyIndex].Fecha_revisado = new Date().toISOString().split('T')[0]; // Fecha actual
        state.properties[propertyIndex].Encargado = encargado; // Usuario actual
      }
    },
    updateObservation: (state, action) => {
      const { id, observacion } = action.payload;
      const propertyIndex = state.properties.findIndex(property => property.Propiedad_id === id);
      if (propertyIndex !== -1) {
        state.properties[propertyIndex].Comentarios = observacion;
      }
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
  },
});

export const { setProperties, setLoading, markAsRevised, updateObservation, setTotalCount } = propertiesSlice.actions;
export default propertiesSlice.reducer;

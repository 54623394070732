import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomSelect.module.css';

const CustomSelect = ({ options, selectedOptions, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(
      options.filter(option =>
        option?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  const handleOptionChange = (option) => {
    let newSelectedOptions = [...(selectedOptions || [])];

    if (newSelectedOptions.includes(option)) {
      newSelectedOptions = newSelectedOptions.filter((item) => item !== option);
    } else {
      newSelectedOptions.push(option);
    }

    onChange(newSelectedOptions);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAll = () => {
    if (filteredOptions.length === selectedOptions.length) {
      onChange([]);
    } else {
      onChange(filteredOptions);
    }
  };

  return (
    <div className={styles.selectContainer}>
      <div className={styles.selectLabel} onClick={toggleDropdown}>
        {label}
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.buttonContainer}>
            <button onClick={handleSelectAll} className={styles.smallButton}>
              {filteredOptions.length === selectedOptions.length ? 'Deseleccionar todo' : 'Seleccionar todo'}
            </button>
          </div>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
          {filteredOptions.map((option, index) => (
            <div key={index} className={styles.option}>
              <input
                type="checkbox"
                checked={(selectedOptions || []).includes(option)}
                onChange={() => handleOptionChange(option)}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomSelect;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, clearFilters, saveFilters } from '../../redux/actions/filtersActions';
import CustomSelect from './CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './Filters.module.css';

const Filters = ({ columns, uniquePages, uniqueProvinces, uniqueLocalidades, uniqueTiposPropiedad, uniquePartidos, onApplyFilters }) => {
  const dispatch = useDispatch();
  const savedFilters = useSelector((state) => state.filters.filters);
  const [filters, setFiltersState] = useState(savedFilters || {});
  const [selectedPages, setSelectedPages] = useState(Array.isArray(savedFilters.Pagina) ? savedFilters.Pagina : []);
  const [selectedProvinces, setSelectedProvinces] = useState(Array.isArray(savedFilters.Provincia) ? savedFilters.Provincia : []);
  const [selectedLocalidades, setSelectedLocalidades] = useState(Array.isArray(savedFilters.Localidad) ? savedFilters.Localidad : []);
  const [selectedTiposPropiedad, setSelectedTiposPropiedad] = useState(Array.isArray(savedFilters.Tipo_propiedad) ? savedFilters.Tipo_propiedad : []);
  const [selectedPartidos, setSelectedPartidos] = useState(Array.isArray(savedFilters.Partido) ? savedFilters.Partido : []);
  const [tituloKeywords, setTituloKeywords] = useState(savedFilters.Titulo || []);
  const [tituloKeywordInput, setTituloKeywordInput] = useState('');
  const [descripcionKeywords, setDescripcionKeywords] = useState(savedFilters.Descripcion || []);
  const [descripcionKeywordInput, setDescripcionKeywordInput] = useState('');
  const [filterName, setFilterName] = useState('');
  const [encargado, setEncargado] = useState('');

  useEffect(() => {
    setFiltersState(savedFilters);
    setSelectedPages(Array.isArray(savedFilters.Pagina) ? savedFilters.Pagina : []);
    setSelectedProvinces(Array.isArray(savedFilters.Provincia) ? savedFilters.Provincia : []);
    setSelectedLocalidades(Array.isArray(savedFilters.Localidad) ? savedFilters.Localidad : []);
    setSelectedTiposPropiedad(Array.isArray(savedFilters.Tipo_propiedad) ? savedFilters.Tipo_propiedad : []);
    setSelectedPartidos(Array.isArray(savedFilters.Partido) ? savedFilters.Partido : []);
    setTituloKeywords(savedFilters.Titulo || []);
    setDescripcionKeywords(savedFilters.Descripcion || []);
  }, [savedFilters]);

  const handleChange = (column, value) => {
    setFiltersState({
      ...filters,
      [column]: value,
    });
  };

  const handleRangeChange = (column, rangeType, value) => {
    setFiltersState({
      ...filters,
      [column]: {
        ...filters[column],
        [rangeType]: value,
      },
    });
  };

  const updateFilters = () => {
    const newFilters = {
      ...filters,
      Pagina: selectedPages.length > 0 ? selectedPages : filters.Pagina,
      Provincia: selectedProvinces.length > 0 ? selectedProvinces : filters.Provincia,
      Localidad: selectedLocalidades.length > 0 ? selectedLocalidades : filters.Localidad,
      Tipo_propiedad: selectedTiposPropiedad.length > 0 ? selectedTiposPropiedad : filters.Tipo_propiedad,
      Partido: selectedPartidos.length > 0 ? selectedPartidos : filters.Partido,
      Titulo: tituloKeywords,
      Descripcion: descripcionKeywords,
    };
    dispatch(setFilters(newFilters));
    return newFilters;
  };

  const applyFilters = () => {
    const newFilters = updateFilters();
    onApplyFilters(newFilters); // Pasar los filtros actualizados
  };

  const resetFilters = () => {
    setFiltersState({});
    setSelectedPages([]);
    setSelectedProvinces([]);
    setSelectedLocalidades([]);
    setSelectedTiposPropiedad([]);
    setSelectedPartidos([]);
    setTituloKeywords([]);
    setDescripcionKeywords([]);
    dispatch(clearFilters());
    onApplyFilters({}); // Llamar la función para cerrar los filtros y restablecerlos
  };

  const handleTituloKeywordInputChange = (e) => {
    setTituloKeywordInput(e.target.value);
  };

  const handleTituloKeywordKeyDown = (e) => {
    if (e.key === 'Enter' && tituloKeywordInput.trim() !== '') {
      setTituloKeywords([...tituloKeywords, tituloKeywordInput.trim().toLowerCase()]);
      setTituloKeywordInput('');
    }
  };

  const handleDescripcionKeywordInputChange = (e) => {
    setDescripcionKeywordInput(e.target.value);
  };

  const handleDescripcionKeywordKeyDown = (e) => {
    if (e.key === 'Enter' && descripcionKeywordInput.trim() !== '') {
      setDescripcionKeywords([...descripcionKeywords, descripcionKeywordInput.trim().toLowerCase()]);
      setDescripcionKeywordInput('');
    }
  };

  const removeTituloKeyword = (index) => {
    setTituloKeywords(tituloKeywords.filter((_, i) => i !== index));
  };

  const removeDescripcionKeyword = (index) => {
    setDescripcionKeywords(descripcionKeywords.filter((_, i) => i !== index));
  };

  const handleSaveFilters = () => {
    const newFilters = updateFilters();
    dispatch(saveFilters(filterName, encargado, newFilters));
  };

  return (
    <div className={styles.filtersContainer}>
      {columns.filter(column => ['Titulo', 'Descripcion', 'Precio', 'Precio_metro', 'Superficie_cubierta', 'Superficie_total', 'Ambientes', 'Antiguedad'].includes(column)).map((column) => (
        <div key={column} className={styles.filterItem}>
          <label className={styles.filterLabel}>{column.replace(/_/g, ' ')}</label>
          {['Precio', 'Precio_metro', 'Superficie_cubierta', 'Superficie_total', 'Ambientes', 'Antiguedad'].includes(column) ? (
            <div className={styles.rangeInputs}>
              <input
                type="number"
                placeholder="Min"
                value={filters[column]?.min || ''}
                onChange={(e) => handleRangeChange(column, 'min', e.target.value)}
                className={styles.rangeInput}
              />
              <input
                type="number"
                placeholder="Max"
                value={filters[column]?.max || ''}
                onChange={(e) => handleRangeChange(column, 'max', e.target.value)}
                className={styles.rangeInput}
              />
            </div>
          ) : (
            column === 'Titulo' ? (
              <>
                <input
                  type="text"
                  placeholder={`Buscar ${column.replace(/_/g, ' ')}`}
                  value={tituloKeywordInput}
                  onChange={handleTituloKeywordInputChange}
                  onKeyDown={handleTituloKeywordKeyDown}
                  className={styles.textInput}
                />
                <div className={styles.keywordsList}>
                  {tituloKeywords.map((keyword, index) => (
                    <div key={index} className={styles.keyword}>
                      {keyword}
                      <FontAwesomeIcon icon={faTimes} onClick={() => removeTituloKeyword(index)} className={styles.removeKeywordIcon} />
                    </div>
                  ))}
                </div>
              </>
            ) : column === 'Descripcion' ? (
              <>
                <input
                  type="text"
                  placeholder={`Buscar ${column.replace(/_/g, ' ')}`}
                  value={descripcionKeywordInput}
                  onChange={handleDescripcionKeywordInputChange}
                  onKeyDown={handleDescripcionKeywordKeyDown}
                  className={styles.textInput}
                />
                <div className={styles.keywordsList}>
                  {descripcionKeywords.map((keyword, index) => (
                    <div key={index} className={styles.keyword}>
                      {keyword}
                      <FontAwesomeIcon icon={faTimes} onClick={() => removeDescripcionKeyword(index)} className={styles.removeKeywordIcon} />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <input
                type="text"
                placeholder={`Buscar ${column.replace(/_/g, ' ')}`}
                value={filters[column] || ''}
                onChange={(e) => handleChange(column, e.target.value)}
                className={styles.textInput}
              />
            )
          )}
        </div>
      ))}
      <div className={styles.selectRow}>
        <div className={styles.selectItem}>
          <CustomSelect
            options={uniquePages.sort()}
            selectedOptions={selectedPages}
            onChange={setSelectedPages}
            label="Página"
          />
        </div>
        <div className={styles.selectItem}>
          <CustomSelect
            options={uniqueProvinces.sort()}
            selectedOptions={selectedProvinces}
            onChange={setSelectedProvinces}
            label="Provincia"
          />
        </div>
        <div className={styles.selectItem}>
          <CustomSelect
            options={uniqueLocalidades.sort()}
            selectedOptions={selectedLocalidades}
            onChange={setSelectedLocalidades}
            label="Localidad"
          />
        </div>
        <div className={styles.selectItem}>
          <CustomSelect
            options={uniqueTiposPropiedad.sort()}
            selectedOptions={selectedTiposPropiedad}
            onChange={setSelectedTiposPropiedad}
            label="Tipo propiedad"
          />
        </div>
        <div className={styles.selectItem}>
          <CustomSelect
            options={uniquePartidos.sort()}
            selectedOptions={selectedPartidos}
            onChange={setSelectedPartidos}
            label="Partido"
          />
        </div>
      </div>
      <div className={styles.filterButtons}>
        <button onClick={applyFilters} className={styles.applyButton}>Aplicar Filtros</button>
        <button onClick={resetFilters} className={styles.resetButton}>Restablecer Filtros</button>
      </div>
      <div className={styles.saveFilterContainer}>
        <input
          type="text"
          placeholder="Nombre del filtro"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          className={styles.textInput}
        />
        <input
          type="text"
          placeholder="Encargado"
          value={encargado}
          onChange={(e) => setEncargado(e.target.value)}
          className={styles.textInput}
        />
        <button onClick={handleSaveFilters} className={styles.saveButton}>Guardar Filtro</button>
      </div>
    </div>
  );
};

Filters.propTypes = {
  columns: PropTypes.array.isRequired,
  uniquePages: PropTypes.array.isRequired,
  uniqueProvinces: PropTypes.array.isRequired,
  uniqueLocalidades: PropTypes.array.isRequired,
  uniqueTiposPropiedad: PropTypes.array.isRequired,
  uniquePartidos: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default Filters;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCheck, faStar } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../CustomModal/CustomModal';
import ObservationModal from './ObservationModal/ObservationModal';
import Filters from '../Filters/Filters';
import { setFilters } from '../../redux/actions/filtersActions';
import { utils, writeFile } from 'xlsx';
import { updatePropertyStatus } from '../../redux/actions/updatePropertyStatus';
import { updatePropertiesObservation } from '../../redux/actions/updatePropertiesObservation';
import styles from './PropertiesTable.module.css';
import { fetchProperties, fetchFilteredProperties } from '../../redux/actions/propertiesActions';
import ModalLoading from '../ModalLoading/ModalLoading';
import { filterProperties, normalizeOptions } from './filterProperties';

const truncateText = (text, limit) => {
  if (!text) return '';
  return text.length > limit ? text.substring(0, limit) + '...' : text;
};

const formatPrice = (price) => {
  return Math.round(price).toLocaleString();
};

const formatDate = (dateString) => {
  const [date] = dateString.split('T');
  return date.split('-').reverse().join('-'); // Adjust the date format as needed
};

const PropertiesTable = () => {
  const properties = useSelector((state) => state.properties.properties);
  const totalCount = useSelector((state) => state.properties.totalCount); // Obtener el total de propiedades
  const loading = useSelector((state) => state.properties.loading);
  const dispatch = useDispatch();
  const savedFilters = useSelector((state) => state.filters.filters); // Obtener los filtros guardados
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [filteredProperties, setFilteredProperties] = useState(properties);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [estadoFilter, setEstadoFilter] = useState('Todos');
  const [encargadoFilter, setEncargadoFilter] = useState('Todos');
  const [observationModalOpen, setObservationModalOpen] = useState(false);
  const [currentObservation, setCurrentObservation] = useState('');
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [limit] = useState(100); // Limitar a 100 resultados por página
  const [offset, setOffset] = useState(0); // Offset para la paginación
  const totalPages = Math.ceil(totalCount / limit); // Calcular el total de páginas
  const currentPage = Math.floor(offset / limit) + 1; // Calcular la página actual

  // Cargar las propiedades una vez al montar el componente
  useEffect(() => {
    if (Object.keys(savedFilters).length === 0) {
      dispatch(fetchProperties(limit, offset));
    } else {
      dispatch(fetchFilteredProperties(limit, offset, savedFilters));
    }
  }, [dispatch, limit, offset, savedFilters]);

  // Actualizar las propiedades filtradas cuando cambian las propiedades, filtros, estado o encargado
  useEffect(() => {
    setFilteredProperties(filterProperties(properties, savedFilters, estadoFilter, encargadoFilter));
  }, [savedFilters, properties, estadoFilter, encargadoFilter]);

  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      const newOffset = offset + limit;
      setOffset(newOffset);
    }
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      const newOffset = offset - limit;
      setOffset(newOffset);
    }
  };

  useEffect(() => {
    if (offset > 0) {
      dispatch(fetchFilteredProperties(limit, offset, savedFilters));
    }
  }, [offset, limit, dispatch, savedFilters]);

  if (loading || properties.length === 0) {
    return <ModalLoading isLoading={loading} />;
  }

  const columns = Object.keys(properties[0]);

  const formatHeader = (header) => {
    return header.replace(/_/g, ' ');
  };

  const openModal = (property) => {
    setSelectedProperty(property);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProperty(null);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const applyFilters = (newFilters) => {
    dispatch(setFilters(newFilters));
    setOffset(0); // Reiniciar el offset al aplicar filtros
    dispatch(fetchFilteredProperties(limit, 0, newFilters)); // Fetch new properties with applied filters
  };

  const downloadExcel = () => {
    const truncatedProperties = filteredProperties.map(property => {
      const truncatedProperty = {};
      columns.forEach(column => {
        truncatedProperty[column] = column === 'Url' ? property[column] : truncateText(property[column], 30);
      });
      return truncatedProperty;
    });

    const ws = utils.json_to_sheet(truncatedProperties);
    const rowCount = filteredProperties.length;
    utils.sheet_add_aoa(ws, [[`Cantidad de filas: ${rowCount}`]], { origin: -1 });

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Filtered Properties');
    writeFile(wb, 'filtered_properties.xlsx');
  };

  const markProperty = (id, estado) => {
    dispatch(updatePropertyStatus(id, estado));
    closeModal();
  };

  const orderedColumns = [
    'Url', 'Revisado', 'Seguimiento', 'Comentarios', 
    'Fecha_cargado', 'Titulo', 'Descripcion', 'Observaciones', 'Moneda', 'Precio', 'Precio_metro', 
    ...columns.filter(col => !['Url', 'Fecha_cargado', 'Titulo', 'Descripcion', 'Moneda', 'Precio', 'Precio_metro', 'Fecha_revisado', 'Encargado', 'Estado', 'Comentarios', 'id', 'Propiedad_id', 'Observaciones'].includes(col)),
    'Fecha_revisado', 'Encargado', 'Estado'
  ];

  const uniquePages = normalizeOptions([...new Set(properties.map((p) => p.Pagina))]);
  const uniqueProvinces = normalizeOptions([...new Set(properties.map((p) => p.Provincia))]);
  const uniqueLocalidades = normalizeOptions([...new Set(properties.map((p) => p.Localidad))]);
  const uniqueTiposPropiedad = normalizeOptions([...new Set(properties.map((p) => p.Tipo_propiedad))]);
  const uniquePartidos = normalizeOptions([...new Set(properties.map((p) => p.Partido))]);
  const uniqueEncargados = normalizeOptions([...new Set(properties.map((p) => p.Encargado))]);

  const openObservationModal = (property) => {
    setCurrentObservation(property.Comentarios || '');
    setCurrentPropertyId(property.Propiedad_id);
    setObservationModalOpen(true);
  };

  const closeObservationModal = () => {
    setObservationModalOpen(false);
    setCurrentObservation('');
    setCurrentPropertyId(null);
  };

  const saveObservation = () => {
    if (currentPropertyId !== null) {
      dispatch(updatePropertiesObservation(currentPropertyId, currentObservation));
    }
    closeObservationModal();
  };

  const handleRowClick = (property, e) => {
    if (!e.target.closest('a') && !e.target.closest('button') && !e.target.closest('.comentarios')) {
      openModal(property);
    }
  };

  const handleObservationClick = (e, property) => {
    e.stopPropagation();
    openObservationModal(property);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button onClick={toggleFilters} className={styles.selectButton}>
          {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
        </button>
        <button onClick={downloadExcel} className={styles.selectButton}>Descargar Excel</button>
        <select value={estadoFilter} onChange={(e) => setEstadoFilter(e.target.value)} className={styles.selectButton}>
          <option value="Todos">Todos</option>
          <option value="NoRevisado">No Revisados</option>
          <option value="Revisado">Revisados</option>
          <option value="Seguimiento">Seguimiento</option>
        </select>
        <select value={encargadoFilter} onChange={(e) => setEncargadoFilter(e.target.value)} className={styles.selectButton}>
          <option value="Todos">Todos</option>
          {uniqueEncargados.map((encargado, index) => (
            <option key={index} value={encargado}>{encargado}</option>
          ))}
        </select>
      </div>
      {filtersVisible && (
        <Filters
          columns={orderedColumns}
          uniquePages={uniquePages}
          uniqueProvinces={uniqueProvinces}
          uniqueLocalidades={uniqueLocalidades}
          uniqueTiposPropiedad={uniqueTiposPropiedad}
          uniquePartidos={uniquePartidos}
          onFilter={applyFilters}
          onApplyFilters={toggleFilters}
        />
      )}
      <div className={`${styles.tableContainer} ${filtersVisible ? '' : styles.fixedHeader}`}>
        <table className={styles.table}>
          <thead>
            <tr>
              {orderedColumns.map((column, index) => (
                <th key={index}>{formatHeader(column)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredProperties.map((property, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr
                  className={
                    property.Estado === 'Revisado'
                      ? styles.revisadoRow
                      : property.Estado === 'Seguimiento'
                      ? styles.seguimientoRow
                      : ''
                  }
                  onClick={(e) => handleRowClick(property, e)}
                >
                  {orderedColumns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className={column === 'Precio' || column === 'Precio_metro' ? styles.numberCell : column === 'Comentarios' ? 'comentarios' : ''}
                      onClick={(e) => column === 'Comentarios' && handleObservationClick(e, property)}
                    >
                      {column === 'Url' ? (
                        <a href={property[column]} target="_blank" rel="noopener noreferrer" className={styles.iconLink}>
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      ) : column === 'Revisado' ? (
                        <button className={styles.revisadoButton} onClick={() => markProperty(property.Propiedad_id, 'Revisado')}>
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                      ) : column === 'Seguimiento' ? (
                        <button className={styles.seguimientoButton} onClick={() => markProperty(property.Propiedad_id, 'Seguimiento')}>
                          <FontAwesomeIcon icon={faStar} />
                        </button>
                      ) : column === 'Comentarios' ? (
                        truncateText(property[column], 30)
                      ) : column === 'Fecha_cargado' ? (
                        formatDate(property[column])
                      ) : column === 'Precio' || column === 'Precio_metro' ? (
                        property[column] ? formatPrice(property[column]) : ''
                      ) : (
                        property[column] ? truncateText(property[column].toString(), 30) : ''
                      )}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.resultsCount}>
        {`Mostrando ${filteredProperties.length} de ${totalCount} resultados`}
      </div>
      <div className={styles.paginationInfo}>
        {`Página ${currentPage} de ${totalPages}`}
      </div>
      <div className={styles.paginationButtons}>
        <button onClick={handlePreviousPage} disabled={offset === 0} className={styles.selectButton}>Anterior</button>
        <button onClick={handleNextPage} disabled={offset + limit >= totalCount} className={styles.selectButton}>Siguiente</button>
      </div>
      {selectedProperty && (
        <CustomModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          title={selectedProperty.Titulo || 'Property Details'}
          content={
            <div className={styles.modalContentGrid}>
              {orderedColumns.map((column, index) => (
                ( column !== 'Descripcion' && column !== 'id' && column !== 'Url' && column !== 'Titulo' && column !== 'Propiedad_id' ) && (
                  <p key={index}>
                    <strong>{column}:</strong> {column === 'Precio' || column === 'Precio_metro' ? formatPrice(selectedProperty[column]) : selectedProperty[column] || 'N/A'}
                  </p>
                )
              ))}
              <div className={styles.modalDescription}>
                <p>
                  <strong>Descripcion:</strong> {selectedProperty.Descripcion || 'N/A'}
                </p>
              </div>
            </div>
          }
          onMarkReviewed={() => markProperty(selectedProperty.Propiedad_id, 'Revisado')}
          onMarkFollowUp={() => markProperty(selectedProperty.Propiedad_id, 'Seguimiento')}
        />
      )}
      <ObservationModal
        isOpen={observationModalOpen}
        onClose={closeObservationModal}
        observation={currentObservation}
        onChange={(e) => setCurrentObservation(e.target.value)}
        onSave={saveObservation}
      />
    </div>
  );
};

export default PropertiesTable;

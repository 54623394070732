import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './FilterDetailsModal.module.css';

Modal.setAppElement('#root');

const FilterDetailsModal = ({ isOpen, onClose, filter, filterStats }) => {
  if (!filter) return null;

  const parsedFilters = JSON.parse(filter.Filtros);

  const renderFilters = (filters) => {
    return Object.keys(filters).map((key) => {
      const value = filters[key];
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        if (value.min !== undefined || value.max !== undefined) {
          return (
            <div key={key} className={styles.filterItem}>
              <strong>{key}</strong>: {value.min !== undefined && `Min: ${value.min}`} {value.max !== undefined && `Max: ${value.max}`}
            </div>
          );
        }
      } else if (Array.isArray(value)) {
        return (
          <div key={key} className={styles.filterItem}>
            <strong>{key}</strong>: {value.length > 0 ? value.join(', ') : 'N/A'}
          </div>
        );
      } else {
        return (
          <div key={key} className={styles.filterItem}>
            <strong>{key}</strong>: {value}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalContent}>
        <h2>Detalles del Filtro</h2>
        <div className={styles.filtersContainer}>
          {renderFilters(parsedFilters)}
        </div>
        <div className={styles.filterStats}>
          <p>Cantidad de propiedades: {filterStats.propertiesCount}</p>
          <p>Cantidad de propiedades sin revisar: {filterStats.unreviewedPropertiesCount}</p>
        </div>
        <button onClick={onClose} className={styles.closeButton}>Cerrar</button>
      </div>
    </Modal>
  );
};

FilterDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.object,
  filterStats: PropTypes.object,
};

export default FilterDetailsModal;

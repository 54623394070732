import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomModal.module.css';

const CustomModal = ({ isOpen, onClose, title, content, onMarkReviewed, onMarkFollowUp }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2 className={styles.modalTitle}>{title}</h2>
        <div className={styles.modalFooter}>
          <button className={styles.markReviewedButton} onClick={onMarkReviewed}>
            Marcar como revisado
          </button>
          <button className={styles.markFollowUpButton} onClick={onMarkFollowUp}>
            Marcar para seguimiento
          </button>
        </div>
        {content}
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onMarkReviewed: PropTypes.func.isRequired,
  onMarkFollowUp: PropTypes.func.isRequired,
};

export default CustomModal;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ObservationModal.module.css';

const ObservationModal = ({ isOpen, onClose, observation, onChange, onSave }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Editar Observaciones</h2>
        <textarea
          value={observation}
          onChange={onChange}
          rows="4"
          className={styles.textarea}
        />
        <div className={styles.modalFooter}>
          <button onClick={onClose} className={styles.cancelButton}>Cancelar</button>
          <button onClick={onSave} className={styles.saveButton}>Guardar</button>
        </div>
      </div>
    </div>
  );
};

ObservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  observation: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ObservationModal;

import React from 'react';
import Modal from 'react-modal';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, registerables } from 'chart.js';
import styles from './StatisticsModal.module.css';

ChartJS.register(...registerables, ChartDataLabels);

const StatisticsModal = ({ isOpen, onRequestClose, captadoresData, inmobiliariasData, zonasData }) => {
  const createBarChartData = (data) => {
    const processedData = data.labels.reduce((acc, label, index) => {
      const value = data.data[index];
      if (label === 'Nadie') {
        acc['Nadie'] = (acc['Nadie'] || 0) + value;
      } else {
        acc[label] = value;
      }
      return acc;
    }, {});

    const labels = Object.keys(processedData);
    const chartData = Object.values(processedData);

    return {
      labels,
      datasets: [
        {
          label: 'Cantidad de agentes por captador',
          data: chartData,
          backgroundColor: labels.map((_, index) => `rgba(${index * 30 % 255}, ${index * 60 % 255}, ${index * 90 % 255}, 0.6)`),
        },
      ],
    };
  };

  const createPieChartData = (data) => {
    const total = data.data.reduce((acc, value) => acc + value, 0);
    const othersThreshold = 1; // Agrupar valores con frecuencia menor a 1%
    const processedData = data.labels.reduce((acc, label, index) => {
      const value = data.data[index];
      if ((value / total) * 100 <= othersThreshold) {
        acc['Otros'] = (acc['Otros'] || 0) + value;
      } else {
        if (label === 'Nadie') {
          acc['Nadie'] = (acc['Nadie'] || 0) + value;
        } else {
          acc[label] = value;
        }
      }
      return acc;
    }, {});

    const labels = Object.keys(processedData);
    const chartData = Object.values(processedData).map(value => (value / total) * 100);

    return {
      labels,
      datasets: [
        {
          label: 'Porcentaje',
          data: chartData,
          backgroundColor: labels.map((_, index) => `rgba(${index * 30 % 255}, ${index * 60 % 255}, ${index * 90 % 255}, 0.6)`),
        },
      ],
    };
  };

  const captadoresChartData = createBarChartData(captadoresData);
  const inmobiliariasChartData = createPieChartData(inmobiliariasData);
  const zonasChartData = createPieChartData(zonasData);

  const barChartOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        align: 'start',
        formatter: (value) => `${value}`,
      },
    },
  };

  const pieChartOptions = {
    plugins: {
      datalabels: {
        display: false, // No mostrar datos en el gráfico
      },
      legend: {
        position: 'bottom',
      },
    },
  };

  const doughnutChartOptions = {
    plugins: {
      datalabels: {
        display: false, // No mostrar datos en el gráfico
      },
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>Estadísticas</h2>
      <div className={styles.chartContainer}>
        <Bar data={captadoresChartData} options={barChartOptions} />
      </div>
      <div className={styles.chartContainer}>
        <Pie data={inmobiliariasChartData} options={pieChartOptions} />
      </div>
      <div className={styles.chartContainer}>
        <Doughnut data={zonasChartData} options={doughnutChartOptions} />
      </div>
      <button onClick={onRequestClose} className={styles.closeButton}>Cerrar</button>
    </Modal>
  );
};

export default StatisticsModal;

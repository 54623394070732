import { combineReducers } from 'redux';
import propertiesReducer from './propertiesReducer';
import authReducer from './authReducer';
import filtersReducer from './filtersReducer';
import crmReducer from './crmReducer'; // Importa el nuevo reducer

const rootReducer = combineReducers({
  properties: propertiesReducer,
  auth: authReducer,
  filters: filtersReducer,
  crm: crmReducer, // Añade el nuevo reducer
});

export default rootReducer;

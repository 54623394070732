import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './Statistics.module.css';

const Statistics = () => {
  const properties = useSelector((state) => state.properties.properties);

  if (!properties || properties.length === 0) {
    return <div>No data available</div>;
  }

  // Obtener los datos únicos y sus respectivas cantidades
  const getCountByField = (field) => {
    const uniqueValues = [...new Set(properties.map((property) => property[field]))];
    const countPerValue = uniqueValues.map((value) => properties.filter((property) => property[field] === value).length);
    return { uniqueValues, countPerValue };
  };

  const pagesData = getCountByField('Pagina');
  const localitiesData = getCountByField('Localidad');
  const partidosData = getCountByField('Partido');

  return (
    <div className={styles.statisticsContainer}>
      <div className={styles.chartContainer}>
        <h3>Propiedades por página</h3>
        <Bar
          data={{
            labels: pagesData.uniqueValues,
            datasets: [
              {
                label: 'Cantidad de propiedades por página',
                data: pagesData.countPerValue,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
      <div className={styles.chartContainer}>
        <h3>Propiedades por localidad</h3>
        <Bar
          data={{
            labels: localitiesData.uniqueValues,
            datasets: [
              {
                label: 'Cantidad de propiedades por localidad',
                data: localitiesData.countPerValue,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
      <div className={styles.chartContainer}>
        <h3>Propiedades por Partido</h3>
        <Bar
          data={{
            labels: partidosData.uniqueValues,
            datasets: [
              {
                label: 'Cantidad de propiedades por partido',
                data: partidosData.countPerValue,
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Statistics;

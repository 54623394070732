import axios from 'axios';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : (process.env.REACT_APP_API_URL || 'https://flippinarq.com/api');

export const fetchCRMData = () => async (dispatch) => {
  dispatch({ type: 'FETCH_CRM_DATA_REQUEST' });

  try {
    const response = await axios.get(`${apiUrl}/crm-data`);
    dispatch({ type: 'FETCH_CRM_DATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_CRM_DATA_FAILURE', error: error.message });
  }
};

const normalizeOptions = (options) => {
    return [...new Set(options.filter(option => option && option.trim() !== '').map(option => option.toLowerCase()))];
  };
  
  const filterProperties = (properties, filters, estadoFilter, encargadoFilter) => {
    return properties.filter((property) => {
      const passesFilters = Object.keys(filters).every((column) => {
        if (typeof filters[column] === 'object' && filters[column] !== null && !Array.isArray(filters[column])) {
          const min = filters[column].min ? parseFloat(filters[column].min) : -Infinity;
          const max = filters[column].max ? parseFloat(filters[column].max) : Infinity;
          return property[column] >= min && property[column] <= max;
        }
        if (Array.isArray(filters[column]) && filters[column].length > 0) {
          return filters[column].some(filterValue =>
            property[column]?.toString().toLowerCase().includes(filterValue.toLowerCase())
          );
        }
        if (typeof filters[column] === 'string' && filters[column].length > 0) {
          return property[column]?.toString().toLowerCase().includes(filters[column].toLowerCase());
        }
        return true;
      });
  
      const passesEstadoFilter = 
        estadoFilter === 'Todos' ||
        (estadoFilter === 'NoRevisado' && !property.Estado) ||
        property.Estado === estadoFilter;
  
      const passesTituloKeywordFilter = filters.Titulo?.length > 0 ? filters.Titulo.some(keyword =>
        property.Titulo?.toLowerCase().includes(keyword)
      ) : true;
  
      const passesDescripcionKeywordFilter = filters.Descripcion?.length > 0 ? filters.Descripcion.some(keyword =>
        property.Descripcion?.toLowerCase().includes(keyword)
      ) : true;
  
      const passesEncargadoFilter = 
        encargadoFilter === 'Todos' ||
        property.Encargado === encargadoFilter;
  
      return passesFilters && passesEstadoFilter && passesTituloKeywordFilter && passesDescripcionKeywordFilter && passesEncargadoFilter;
    });
  };
  
  export { filterProperties, normalizeOptions };
  